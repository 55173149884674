html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

/* swiperjs overrides */
.swiper-wrapper {
  align-items: center;
}

.swiper-container {
  height: 20rem;
}

@media screen and (min-width: 1500px) {
  .swiper-container {
    height: 25rem;
  }
}

@media screen and (min-width: 1920px) {
  .swiper-container {
    height: 30rem;
  }
}

@media screen and (min-width: 2200px) {
  .swiper-container {
    height: 20vw;
  }
}

.swiper-slide {
  height: auto;
}

.swiper-slide-zoomed {
  z-index: 1;
}

.grecaptcha-badge { 
  visibility: hidden;
}